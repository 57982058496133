.logo-section {
  flex-direction: column;
  display: flex;
}

.logo {
  -webkit-filter: drop-shadow(5px 5px 5px #33311b);
  filter: drop-shadow(5px 5px 5px #33311b);
  animation: 1s ease-in-out both fadeIn;
}

.steam {
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  animation: 1s ease-in-out .5s both slideIn;
  display: flex;
}

.discord-section {
  box-sizing: border-box;
  background: #a0c0da;
  border: 3px solid #33311b;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  margin: 1% 0;
  padding: 10px 30px;
  animation: 1s ease-in-out 1s both slideIn;
  display: flex;
}

.discord-section h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.discord-btn {
  color: #fff;
  -webkit-filter: drop-shadow(2px 2px 2px #33311b);
  filter: drop-shadow(2px 2px 2px #33311b);
  background-color: #7289da;
  border: 2px #33311b;
  border-radius: 5px;
  padding: 10px 20px;
  text-decoration: none;
  animation: 2s ease-in-out infinite pulse;
}

.discord-btn:hover {
  background-color: #677bc4;
}

.subscribe {
  box-sizing: border-box;
  background: #afafaf;
  border: 3px solid #33311b;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  margin: 1% 0;
  padding: 10px 30px;
  animation: 1s ease-in-out 1.5s both slideIn;
  display: flex;
}

.subscribe h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

#status-message {
  margin-top: 10px;
  font-weight: bold;
}

form {
  flex-direction: column;
  align-items: center;
  display: flex;
}

input[type="email"] {
  width: 100%;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

button[type="submit"] {
  color: #fff;
  cursor: pointer;
  background-color: #333;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
}

.email-disclaimer {
  color: #333;
  font-size: .8rem;
}

.loader {
  width: 20px;
  height: 20px;
  border: 4px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: 2s linear infinite spin;
  display: none;
}

.success-icon {
  color: green;
  display: none;
}

.ppp-background {
  text-align: center;
  min-width: 320px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background: url("backrooms_glitch.04e15139.webp") center / cover no-repeat fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: Nunito Sans, Verdana, sans-serif;
  display: flex;
  position: absolute;
  inset: 0;
}

.ppp-main {
  box-sizing: border-box;
  width: calc(100% - 20px);
  max-width: 1000px;
  max-height: 95%;
  -webkit-filter: drop-shadow(5px 5px 5px #33311b);
  filter: drop-shadow(5px 5px 5px #33311b);
  background: #d3d4b6;
  border: 5px solid #33311b;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px;
  animation: 1s ease-in-out both fadeIn;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.links-section {
  box-sizing: border-box;
  background: #f1f7c4;
  border: 3px solid #33311b;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  margin: 1% 0;
  padding: 10px 30px;
  animation: 1s ease-in-out 2s both slideIn;
  display: flex;
}

.links-section h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.links-section ul {
  padding: 0;
  list-style: none;
}

.links-section ul li {
  box-sizing: border-box;
  background: #f4f7d9;
  border: 3px solid #33311b;
  border-radius: 5px;
  margin-bottom: 10px;
}

.links-section ul li a {
  color: #333;
  padding: 10px 30px;
  font-size: 1.2rem;
  text-decoration: none;
  display: block;
}

.links-section ul li:hover {
  background: #c9cea1;
}

.links-section ul li i {
  margin-right: 10px;
  font-size: 1.4rem;
}

@media (width >= 768px) {
  .ppp-main {
    text-align: center;
    align-items: center;
    padding: 20px 60px;
  }

  .steam {
    font-size: 1.5rem;
  }

  .discord-section h2, .subscribe h2 {
    font-size: 2rem;
  }

  form {
    flex-direction: row;
  }

  input[type="email"] {
    margin-bottom: 0;
    margin-right: 10px;
  }

  .links-section h2 {
    font-size: 2rem;
  }
}

@media (width <= 420px) {
  .logo {
    height: 95%;
  }

  .ppp-main {
    align-items: center;
    padding: 5%;
  }

  .steam {
    font-size: 1.25rem;
  }

  .discord-section h2, .subscribe h2 {
    font-size: 1.5rem;
  }

  form {
    flex-direction: row;
  }

  input[type="email"] {
    margin-bottom: 0;
    margin-right: 10px;
  }

  .links-section h2 {
    font-size: 1.5rem;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.025);
  }
}

/*# sourceMappingURL=index.97a8186a.css.map */
